<script setup>
import {defineProps} from 'vue'

const props = defineProps(["data"])

function try_file(id) {
  try {
    return require('@/assets/icons/providers/' + id + '.svg')
  } catch {
    return require('@/assets/icons/providers/' + id + '.png')
  }
}
</script>

<template>
  <div class="d-flex vertically-centered">
    <img v-if="props.data.name != ''" height="16" class="me-2" :src="try_file(props.data.id)" alt="">
    <span v-if="props.data.name">{{ props.data.name }}</span>
  </div>
</template>

<style scoped>

</style>
