<script setup>
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

mainStore.nav_resources = 'About'
</script>

<template>
<div class="container pt-5">
  <div class="row">
    <div class="col-md-12">
        <h3 >Mission</h3>
        <p>Webscout’s mission is to simplify the process of hunting evil and identifying vulnerabilities at scale in cyberspace. Through deep enrichment and seamless pivoting between observations and patterns, our aim is to provide a community platform for cyber analysts to investigate anything on the internet.</p>
    </div>
    <div class="col-md-12">
        <h3>The Challenge</h3>
        <p>The complexity of digital technologies and the offensive advantage of cyberattacks present an adverse environment that is not only challenging to navigate but also demands a high degree of knowledge and expertise. Acquiring the necessary data and intelligence to navigate this terrain can be costly and difficult to procure, and this calls for a democratization of threat intelligence. That is the challenge we aim to tackle.</p>
    </div>
    <div class="col-md-12">
        <h3>Vision</h3>
        <p>Building the first truly open CTI community. Our vision is to establish a platform where analysts and defenders of all experience levels can collaborate on uncovering malicious infrastructure and tracking adversaries at a scale. A significant aspect of this is to enable intuitive enrichment of selectors in real-time. Based on our collection sources we strive to provide unmatched meaning and context to every system on the internet. By incorporating public and private RSS-style subscription threat feeds from omniscient user's fingerprints and alerts, we aspire to create a continuous flow of critical intelligence to foster a proactive and collaborative approach to cybersecurity.</p>
    </div>
    <div class="col-md-12">
        <h3>API</h3>
        <p>The core platform we are building is a web-based GUI. For more advanced users seeking to enrich web selectors in bulk or query internet-wide data, we will be offering an API to interact with your data and export key datasets to on-prem or air-gapped CTI platforms. We encourage analysts to feed actionable intelligence back to the community to fortify digital resilience.</p>
    </div>
  </div>
</div>

</template>

<style scoped>

</style>

