<script setup>
import {defineProps, ref, nextTick, onMounted, computed} from 'vue'
import markdownit from 'markdown-it'
import mda from 'markdown-it-anchor'
import slugify from 'slugify'

const props = defineProps({
  data: {
    type: String,
    default: ""
  },
  toc: {
    type: Boolean,
    default: false
  }
});

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

let nav = [];

let level = 0; 
let index = 0; 

function getTokensText (data, text) {
  let l = 0; 
  if (data.tag == "h1") {
    text.children = [];
    nav.push(text)
  }
  else if (data.tag == "h2") {
    text.children = [];
    nav[nav.length-1].children.push(text)
  }
}

function slug(data) {
  return "section-"+slugify(data)
}
// commonmark mode
const md = markdownit('commonmark')
md.use(mda, { slugify: slug, callback: getTokensText } )

const results = md.render(props.data) 
</script>

<template>
  <div class="row" style="position: relative;">
    <div class="col-md-12" v-html="results"></div>
    <!--
    <div class="col-md-3">
        <nav id="navbar-md" ref="spynav_elem" v-if="toc" class="navbar navbar-light flex-column align-items-stretch p-3" style="position: sticky; top: 1rem;">
          <a class="white" href="#"><strong>on this page</strong></a>
          <hr>
          <nav class="nav nav-pills flex-column" >
            <div v-for="h in nav" :key="h">
            <a class="nav-link sib-activator" :href="'#' + h.slug" >{{ h.title }}</a>
            <nav class="nav nav-pills flex-column sib-a-active">
              <a class="nav-link ms-3 my-1" :href="'#' + hc.slug" v-for="hc in h.children">{{ hc.title }}</a>
            </nav>
            </div>

          </nav>
        </nav>
    </div>
    -->
  </div>
</template>


<style scoped>
.sib-a-active {
  display: none;
  transition: opacity .5s ease;
  opacity: 0;
}

.sib-activator.active + .sib-a-active {
  display: block;
  opacity: 1;
}


</style>
