<script setup>
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

mainStore.nav_resources = 'Contact'
</script>

<template>
<div class="container pt-5">
  
  <div class="row">
    <div class="col-md-12">
        <h2 class="text-center mbb-4">We are always ready to help</h2>
        <div class="row" style="display:flex; align-items: center;" >
          <div class="col-md-7">
            <h3 class="title mb-3">How to reach us</h3>
            <p class="subsubheader">For general inquiries about Webscout and what we can offer, please send us an email at info@webscout.io and we will get back to you shortly. If you have encountered a bug or a security issue, please report the issue to our security team at security@webscout.io.</p>
          </div>
          <div class="col-md-5">
            <img src="@/assets/webscout-team.svg" style="width:100%;" alt="">
          </div>
        </div>
    </div>
  </div>

</div>

</template>

<style scoped>

.subsubheader {
  font-size: 1.1rem;
}

</style>

