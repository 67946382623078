<script setup>
import { onMounted, ref, computed } from 'vue'
import Feeds from '../../api/Feeds';
import Slideover from '../../components/SlideOver/slideover.vue'
import FeedComp from '../../components/SlideOver/components/Feed.vue'
import FeedForm from '../../components/SlideOver/forms/CreateFeed.vue'
import StandardTable from '../../components/Table/Standard.vue'
import { useUserStore } from '@/stores/user'
import moment from 'moment'
import { useMainStore } from '@/stores/main'
let mainStore = useMainStore()

import Streams from '@/data/streams.js'
import Databases from '@/data/databases.js'

import Database from '@/components/Feeds/Database'
import Stream from '@/components/Feeds/Stream'

const userStore = useUserStore()
const feeds = ref([])

onMounted(async () => {
  feeds.value = (await Feeds.featured()).data
})
</script>

<template>
  <div style="min-height: 100%;" class="p-3">
    
    <div class="mb-5">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <font-awesome-icon style="color: #e7f25c; font-size: 1.2rem;" class="me-4" :icon="['fas', 'square-rss']" />
        <h5 class="mb-0">Streams</h5>
      </div>
      
      <div class="d-flex">
        <div v-for="stream in Streams" class="me-3" :key="stream">
          <Stream :data="stream"/>
        </div> 
      </div>
    </div>

    <div class="">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <font-awesome-icon style="color: rgb(146, 139, 232); font-size: 1.2rem;" class="me-4" :icon="['fas', 'database']" />
        <h5 class="mb-0">Datasets</h5>
      </div>
     
      <div class="d-flex">
        <div v-for="database in Databases" class="me-3" :key="database">
          <Database :data="database"/>
        </div> 
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>
