<script setup>
import {computed, defineEmits, onMounted, onUnmounted, ref, watch, nextTick} from 'vue'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import DataTablesSelect from 'datatables.net-select';
import { useQueryStore } from '@/stores/query'
let queryStore = useQueryStore()

const props = defineProps({
  result: {
    type: Array
  },
});
// IMPORT WIDGETS
import Country from '@/components/Query/Widget/Country.vue'
import Tags from '@/components/Query/Widget/Tags.vue'
import DateI from '@/components/Query/Widget/Date.vue'
import Indicators from '@/components/Query/Widget/Indicators.vue'
import Provider from '@/components/Query/Widget/Provider.vue'
import Anonymization from '@/components/Query/Widget/Anonymization.vue'
import Since from '@/components/Query/Widget/Since.vue'
import Reputation from '@/components/Query/Widget/Reputation.vue'
import IpWidget from '@/components/Query/Widget/Ip.vue'
import AsNumber from '@/components/Query/Widget/AsNumber.vue'
import AsOrg from '@/components/Query/Widget/AsNumber.vue'

DataTable.use(DataTablesCore)
DataTable.use(DataTablesSelect)

const emit = defineEmits(['rowclick'])

const renderComp = ref(true)

let page_size = ref(10);

let options = computed(() => {
  return {
    pagingType: 'simple_numbers',
    paging: true,
    scrollCollapse: true,
    scrollY: true,
    scrollX: true,
    drawCallback: function() {
      document.querySelector(".dt-scroll-body thead").style.display = "none"
    },
    //select: {
    //  style: "os",
    //  items: 'cell'
    //},
    layout: {
        topStart: null,
        topEnd: null,
        bottomStart: "info",
        bottomEnd: "paging"
    },
    pageLength: 50,
  }
})

let result = computed(() => {
  if (props.result != undefined) {
    let r = flatten(props.result)
    return {
      data: r,
      columns: generate_columns(r)
    }
  } else {
    let r = flatten(queryStore.result)
    return {
      data: r,
      columns: generate_columns(r)
    }
  }

})

// we want to flatten some columns but in a standardized way
function flatten(query_result) {
  if (query_result == null || query_result == undefined) return query_result

  for (let i=0; i<query_result.length;i++) {
    flatten_as(query_result[i])
    flatten_provider(query_result[i])
    flatten_article(query_result[i])
    flatten_country(query_result[i])

    delete_keys(query_result[i])
  }

  return query_result
}

function flatten_as(obj) {
  if ("as" in obj) {

    if (Array.isArray(obj.as)) {
      let items = obj.as.map( (w) => w.number.toUpperCase())
      obj.as_number = [... new Set(items) ]

      let itemss = obj.as.map( (w) => w.org)
      obj.as_org = [... new Set(itemss) ]
    } else {
      obj.as_number = obj.as.number
      obj.as_org = obj.as.org
    }
    
    delete obj.as
  }
}

function flatten_country(obj) {
  if ("location" in obj && Array.isArray(obj['location'])) {
    let setObj = new Set(obj['location'].map(JSON.stringify));
    obj.location = Array.from(setObj).map(JSON.parse);
  }
}

function flatten_article(obj) {
  delete obj.summary
  delete obj.id
}

function delete_keys(obj) {
  /*
  delete obj.expiration_date
  delete obj.registrant_org
  */
  if (queryStore.query_string.includes("subdomains=") || queryStore.query_string.includes("subdomains =")) {
    delete obj.registrant_org
    delete obj.registration_date
    delete obj.expiration_date
  }
  delete obj.registrant_email
}

function flatten_provider(obj) {
  if ("provider" in obj ) {

    if (obj.provider == null) {
      obj.provider_reputation = null
      return 
    }

    obj.provider_reputation = obj.provider.reputation
  }
}

// FIX There could be a possible flaw here, if there are missing fields from the output. Ie. if fields are omitted if they are empty / null
function generate_columns(result) {
  let columns = []
  if (result != null && result.length > 0) {
    for (const [key, value] of Object.entries(result[0])) {
      columns.push({ data: key, title: key, name: key })
    }
  }
  return columns
}

const table = ref()
const rtable = ref()
let dt; 

function resize() {
  let table_window = document.querySelector(".dt-layout-table")
  let row = document.querySelector(".querytable tbody tr")
  
  if (table_window == null || row == null) {
    return
  }

  let table_window_size = table_window.offsetHeight
  let row_size = row.offsetHeight
  if (table_window_size == null || row_size == null) {
    page_size.value = 20;
  } else {
    let size = Math.floor((table_window_size-row_size)/row_size)
    if (size > 1) {
      page_size.value = size
    }
  }

  dt.page.len(page_size.value).draw()
}


onMounted(() => {
  dt = table.value.dt;

  dt.on('click', 'tbody tr td', function() {
    emit("rowclick", { 
      obj: dt.row(this).data(),
      cell_name: dt.column(this).header().textContent,
      //cell_data: rdt.cell(this).data() 
    })
  });

  //resize()
  //window.onresize = resize
})

function handle_keydown(event) {
  if (event.ctrlKey && event.key === 'g') {
    event.preventDefault();
  }
}

onMounted( () => {
  document.addEventListener('keydown', handle_keydown);
})

onUnmounted( () => {
  document.removeEventListener('keydown', handle_keydown)
})
</script>

<template>
  <div ref="rtable" class="" style="height: 100%">
      <DataTable
        ref="table"
        :options="options"
        style="height: 100%;"
        :columns="result.columns"
        :data="result.data"
        class="querytable pageResize">


        <template #column-ips="props" v-if="result != null">
          <IpWidget :data="props.cellData"/>
        </template>

        <template #column-as_number="props" v-if="result != null">
          <AsNumber :data="props.cellData"/>
        </template>

        <template #column-as_number="props" v-if="result != null">
          <AsOrg :data="props.cellData"/>
        </template>

        <template #column-location="props" v-if="result != null">
          <Country :data="props.cellData"/>
        </template>
        
        <template #column-provider="props"  v-if="result != null" >
          <Provider :data="props.cellData"/>
        </template>

        <template #column-osint_mentions="props">
          <Indicators :data="props.cellData" />
        </template>

        <template #column-indicators="props">
          <Indicators :data="props.cellData" />
        </template>

        <template #column-tags="props">
          <Tags :data="props.cellData" />
        </template>

        <template #column-ip_indicators="props">
          <Indicators :data="props.cellData" />
        </template>

        <template #column-anonymization="props">
          <Anonymization :data="props.cellData" />
        </template>

        <template #column-update_date="props">
          <Since :data="props.cellData" />
        </template>

        <template #column-publish_date="props">
          <Since :data="props.cellData" />
        </template>

        <template #column-expiration_date="props">
          <DateI :data="props.cellData" />
        </template>

        <template #column-registration_date="props">
          <DateI :data="props.cellData" />
        </template>

        <template #column-provider_reputation="props">
          <Reputation :data="props.cellData" />
        </template>

        <template #column-domains_on_ip="props">
          <div>
            <span v-if="props.cellData >= 1000">1000+</span>
            <span v-else>{{props.cellData}}</span>
          </div>
        </template>

      </DataTable>
  </div>
</template>

<style>
.dt-input {
  background-color: #131820;
  border: none;
  padding: .5rem 1rem;
  color: white;
  border-radius: 10px;
  outline: none;
}

.datatable { 
  min-width: 100%;
}

.datatable tr:hover {
  background-color: #1f252f;
}

.datatable tr td:hover {
  background-color: #262e3a;
}

.datatable td {
  word-break: break-all;
  nowrap: nowrap
}

.datatable td {
  border-right: 1px solid #1e252f;
  border-left: 1px solid #1e252f;
  /*verflow: hidden;
  text-overflow: ellipsis;
  */
  white-space: nowrap;
  max-width: 30rem;
}

.datatable td.selected {
  background-color: #364c6a;
}

.datatable th:not(:first-child) {
  border-right: 1px solid #1e252f;
  border-left: 1px solid #1e252f;
}

.px200 {
  overflow: hidden;
  max-width: 300px;
  word-wrap: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dataTables_filter {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: .7rem;
}

.querytable th {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #151a22;
  border-bottom: 2px solid #191f27;
  color: white;
  border-top: 2px solid #191f27;
}

.querytable td,th {
  /*
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  */
  padding: .5rem 1rem;
}

.dt-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.datatable {
  height: 100%;
}

.querytable tbody tr {
  border-bottom: 1px solid #1E252F;
}

.dt-layout-table {
  flex: 1;
  min-height: 0; 
}

.dt-layout-row:has(.dt-start) {
  border-top: 2px solid #191f27;
  background-color: #151a22;
  display: flex;
  padding: 1rem 2rem;
}

div.dataTables_scrollBody .h_cell {
  padding: 0 4px !important;
}

.dt-layout-row .dt-end {
  margin-left: auto;
}

.paging_simple_numbers button {
  background-color: #1E252F;
  border-radius: 5px;
  border:none;
  margin-right: 5px;
  padding: 0 10px;
  font-weight: bold;
  color: white;
}

.paging_simple_numbers button:hover:not(.current) {
  background-color: #2B3440;
}
.dt-paging-button.current {
  background-color: #37414F;
}

.dt-scroll-body table {
  overflow: hidden; 
}
.dt-scroll-body thead {
  /*
  display: none;
  */
  visibility: collapse;
}


.dt-layout-cell {
  height: 100%;
  align-self: stretch;
}

.dt-scroll {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dt-scroll-body {
  max-height: calc(100% - 42px)
}
</style>
