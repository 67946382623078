<script setup>
import Feeds from '../../../api/Feeds';
import StandardTable from '../../../components/Table/Standard.vue'
import {defineProps, ref, onMounted, computed} from 'vue'
import moment from 'moment'
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import Markdown from '../../../components/Markdown.vue'

const props = defineProps(['item'])
const details = ref(null)
const content = ref([])

const loading = ref(true)
const articles = ref([]) 
const feed_hits = computed(() => {})

onMounted(async () => {
  loading.value = true;
  
  details.value = (await Feeds.details(props.item)).data
  
  //content.value = (await Feeds.list_items(props.item, 0)).data
  loading.value = false;
})

let col = [
  { data: "Data", render: (data) => {
    return JSON.stringify(data)
  } },
  { data: "Time", render: (data) => {
    return moment(data).fromNow()
  } },
]

</script>

<template>
  <div class="streams">
    <div v-if="!loading">
      <div class="mb-3">
        <h4>{{ details.Name }}</h4>
        <p v-if="details.Owner != 'Webscout'">{{ details.Owner }}</p>
        <img v-else src="~@/assets/webscout.svg" alt="" ref="logoref" width="90">
      </div>
      <div class="row mb-4">
        <div class="col-md-12">
          <div class="d-flex">
            <div class="box small-padded me-2 d-flex vertically-centered" style="border-radius: 10px;">
              <font-awesome-icon class="me-2" style="color: #536173; font-size: 1rem;" :icon="['fas', 'database']" />
              <div class="me-4">Items</div>
              <div class="white"><strong>{{details.ItemCount}}</strong></div>
            </div>

            <div class="box small-padded d-flex vertically-centered" style="border-radius: 10px;">
              <font-awesome-icon class="me-2" style="color: #536173; font-size: 1rem;" :icon="['fas', 'clock-rotate-left']" />
              <div class="me-4">Last update</div>
              <div class="white"><strong>{{ moment(details.LastUpdated).fromNow()}}</strong></div>
            </div>

          </div>
        </div>
      </div>
      <div>
        <div class="row mb-4">
          <div class="col-md-12">
            <p v-if="details.Description != ''"><markdown :data="details.Description"/></p>
            <p v-else>No Description</p>
            <hr>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-12">
            <h5 class="mb-3">Schema</h5>
            <div class="box p-3">
              <vue-json-pretty :data="details.Schema"/>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <h5 class="mb-3">API Access</h5>
            <div class="box p-3">
              <div>   
                <pre class="m-0">curl -X GET https://api.webscout.io/feeds/{{details.Id}}/data?apikey=&lt;INSERT KEY HERE&gt;</pre>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <div v-else>
      loading
    </div>
  </div>
</template>

<style scoped>
.streams {
  padding: 2rem;
}
</style>
