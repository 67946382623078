<script setup>
import {defineProps, ref, onMounted, computed} from 'vue'
import Slideover from '../../components/SlideOver/slideover.vue'
import FeedComp from '../../components/SlideOver/components/Feed.vue'
import { useMainStore } from '@/stores/main'
import moment from 'moment'
import Feeds from '../../api/Feeds';

const mainStore = useMainStore()
mainStore.nav_resources = ''

const categories = computed(() => {
  let categories = {}

  for (let feed of feeds.value) {
    if(feed.Category == "") {
      feed.Category = "Unlabeled"
    }
    if (!(feed.Category in categories)) {
      categories[feed.Category] = []
    }

    categories[feed.Category].push(feed);
  }

  return categories
})

async function close() {
  show_slideover.value = false
} 

const loading = ref(null)
const feeds = ref([])

const show_slideover = ref(false)
const slideover_component = ref(null)
const slideover_props = ref(null)

onMounted(async () => {
  loading.value = true;
  
  feeds.value = (await Feeds.featured()).data

  loading.value = false;
})

function show_feed(feed) {
  slideover_props.value = { "item": feed }
  slideover_component.value = FeedComp
  show_slideover.value = true
}

let col = [
  { data: "Data", render: (data) => {
    return JSON.stringify(data)
  } },
  { data: "Time", render: (data) => {
    return moment(data).fromNow()
  } },
]

</script>
<template>
  <div class="container pt-5">
    <slideover :show="show_slideover" :component="slideover_component" :properties="slideover_props" @slideover-close="close()"></slideover>
    <div class="mb-5">
      <h1>Feeds</h1>
         <p style="font-size: 1.1rem;">
A security feed is a continuous stream of data captured by our, or our partners, surveillance systems. They provide real-time access to observations, analysis of activities, and events. These are useful for enhancing situational awareness, identifying potential threats, and facilitating a proactive defense against threats. They serve as invaluable tools for safeguarding properties, assets, and individuals.
         </p>

         <p style="font-size: 1.1rem;">
         All feeds require an API-key to access, which can be generated once logged in.  
         </p>
    </div>

    <div v-for="feeds, category in categories" class="mb-4" :key="category">
      <h3>{{ category }}</h3>
      <hr>
      <div v-for="feed in feeds" :key="feed">

        <div class="box clickable" @click="show_feed(feed.Id)">
          <div class="header">
            <div class="d-flex">
              <h5>{{feed.Name}}</h5>
              <div class="ms-auto">
                <img src="~@/assets/webscout.svg" alt="" ref="logoref" width="90">
              </div>
            </div>
            <p>{{feed.Summary}}</p> 
            <div class="d-flex">
              <div class="me-4">
                <font-awesome-icon class="me-2" style="color: #536173; font-size: 1rem;" :icon="['fas', 'database']" />
                {{ feed.ItemCount }}
              </div>
              <div class="me-4">
                <font-awesome-icon class="me-2" style="color: #536173; font-size: 1rem;" :icon="['fas', 'clock-rotate-left']" />
                {{ moment(feed.LastUpdated).fromNow() }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
