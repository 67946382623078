<script setup>
import {onMounted, ref, defineProps, onUnmounted, computed, nextTick, watch} from 'vue'
import { useStreamStore } from '@/stores/stream'
let streamStore = useStreamStore()

import StreamTable from '@/components/Stream/Table.vue'
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

async function copy_result_to_clipboard() {
  let str = JSON.stringify(streamStore.result);
  await navigator.clipboard.writeText(str)
}

const renderTable = ref(true)

watch( () => streamStore.stream_name, async ()=> {
  renderTable.value = false;
  await nextTick()
  renderTable.value = true;
})

onUnmounted( () => {
  streamStore.stop()
})
</script>

<template>
  <div v-if="!streamStore.failed" style="height: 100%; display: flex; ">

    <div v-if="!streamStore.empty && renderTable" style=" display: flex; min-height: 0; flex: 1; flex-direction: column;">
        <vue-json-pretty v-if="streamStore.display_json" :data="streamStore.data" style="overflow: auto; padding: 1rem; flex:1;"/>
        <StreamTable v-else style="min-height: 0; flex: 1;" />
        <div class="jsonheader">
          <div class="clickable" @click="copy_result_to_clipboard">
            <font-awesome-icon class="me-2" style="color: white; font-size: 1rem;" :icon="['fas', 'copy']" />
            copy to clipboard
          </div>
          <div class="clickable" @click="streamStore.display_json = !streamStore.display_json">
            <font-awesome-icon v-if="streamStore.display_json" class="me-2" style="color: white; font-size: 1rem;" :icon="['fas', 'table-list']" />
            <font-awesome-icon v-else class="me-2" style="color: white; font-size: 1rem;" :icon="['fas', 'code']" />
            <span v-if="streamStore.display_json">display as table</span>
            <span v-else>display as json</span>
          </div>
        </div>
    </div>
  </div>
  <div v-else>
    <div class="d-flex column centered pt-5" style="height: 100%;">
      <font-awesome-icon style="font-size: 7rem; color: rgb(202, 80, 80);" class="mb-4" :icon="['fas', 'circle-exclamation']"/>
      <div>
        <h4 class="text-center">Stream error</h4>
        <p class="text-center">
            {{ streamStore.error }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

.jsonheader {
  display: flex;
  background-color: #151a22;
  border-top: 2px solid #191f27;
  color: white;
  height: 43px;
}

.jsonheader div {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-left: 1px solid #191f27;
}

.clickable:hover {
  background-color: #2B3440;
  cursor: pointer;
}

.clickable {
  font-weight: normal;
}
</style>
