<script setup>
import { onMounted, computed, ref, watch } from 'vue'
import Articles from '../../api/Article';
import Slideover from '../../components/SlideOver/slideover.vue'
import moment from 'moment'
//import { useUserStore } from '@/stores/user'
//const user = useUserStore()
let search_input = ref(null)
let articles = ref([])

let selected_filters = ref(["Has Tag"])
let offset = ref(0)
let more_results = ref(true)

let filters = {
  "Breach": 0,
  "Crime": 1,
  "Hacktivism": 2,
  "Espionage": 3,
  "Vulnerability": 4,
  "Malware": 5,
  "Leak": 6,
  "Patch": 7,
  "Privacy": 8,
  // Others
  "Has Tag": 9,
  "No Tag": 10
}

// slideover
const show_slideover = ref(false)
const slideover_component = ref(null)
const slideover_props = ref(null)
const article_loading = ref(false)

let available_filters = computed(() => {
  let af = {}
  for (const [key, value] of Object.entries(filters)) {
    if (selected_filters.value.includes(key)) { continue }
    af[key] = value
  }
  return af
})

function remove_tag(tag) {
  if (selected_filters.value.includes(tag)) {
    selected_filters.value = selected_filters.value.filter((e) => {
      return e != tag
    })
  }
}

function add_tag(tag) {
  if (tag in filters) {
    selected_filters.value.push(tag)
  }
}

let selectedArticle = ref(null)
let selectedArticleIndicators = ref([])

onMounted(async () => {
  await search_article("none")
})

async function select_article(article) {
  // the another article / or perhaps the same is already loading?
  show_slideover.value = true

  article_loading.value = true

  // with slideover we are not loading he article on each request rather than always showing the result to the right
  
  selectedArticle.value = article
  selectedArticleIndicators.value = (await Articles.indicators(selectedArticle.value.Id)).data

  article_loading.value = false
}

watch(search_input, async (new_input) => {
  /*
  This is for live search
  new_input = new_input.trim()
  console.log(new_input, old_input)
  if (new_input.length < 2 && old_input != null && old_input.length > 2) {
    console.log("HELLO")
    articles.value = (await Articles.list()).data
    return
  }
  if (new_input.length < 2) return

  await search_article(new_input)
  */
  if (new_input == "" || new_input == null) {
    search_article("")
  }
})

watch(selected_filters, async() => {
  await search_article(search_input.value)
}, { deep: true })

async function search_article(query) {
  offset.value = 0
  articles.value = (await Articles.search(query, selected_filters.value, offset.value)).data 
  offset.value = articles.value.length
  if (articles.value.length < 0) {
    more_results.value = false;
  }
}


async function load_more() {
  let new_res = (await Articles.search(search_input.value, selected_filters.value, offset.value)).data
  articles.value = articles.value.concat(new_res)
  offset.value = articles.value.length
  if (new_res.length == 0) {
    more_results.value = false;
  }
}
</script>

<template>
  <div class="">
    <div class="container-fluid">
      <div class="row"  style="position: sticky; top:0; z-index: 1;" > 

        <div class="d-flex column p-0">
          <div class="search p-0 d-flex column">
            <form class="search_form me-2 d-flex" style="flex-grow: 1" @submit.prevent="search_article(search_input)">
              <input class="search_input" v-model="search_input" placeholder="Search">

              <div class="d-flex">
                <div class="d-flex">
                  <div class="active-tag me-2" v-for="filter in selected_filters" :key="filter">
                    <span class="me-2">
                      {{ filter }}
                    </span>
                    <span>
                      <font-awesome-icon @click="remove_tag(filter)" class="active-tag-cross" style="color: #536173; font-size: .8rem;" :icon="['fas', 'xmark']" />
                    </span>
                  </div>
                </div>

                <div class="dropdown d-flex" data-bs-theme="dark">
                  <div class="active-tag dropdown-toggle" style="cursor:pointer;"  data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                    + filter
                  </div>
                  <ul class="dropdown-menu dropdown-menu-lg-end">
                    <li v-for="_,filter in available_filters" :key="filter" @click="add_tag(filter)"><span class="dropdown-item">{{filter}}</span></li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div>
          <div style="flex-grow: 1; overflow: scroll; padding-bottom: 1rem;">
            <div @click="select_article(article)" class="d-flex mb-4 article" v-for="article in articles" :key="article">
              <div class="d-flex column">
                <div class="d-flex vertically-centered">
                  <h5 class="m-0 me-3 mb-2 fw-normal">{{ article.Title }}</h5>
                  <small class="fst-italic">{{ article.OwnerDisplayName }}</small>
                </div>
                <p class="mb-2" style="max-height: 100px; overflow: hidden; text-overflow: ellipsis;">{{ article.Summary}}</p>

                <div class="d-flex vertically-centered">
                  <div class="me-4">{{ moment(article.PublishDate).fromNow() }}</div>
                  <div class="d-flex me-4 vertically-centered">
                    <font-awesome-icon class="me-2" style="color: #536173; font-size: 1rem;" :icon="['fas', 'database']" />
                    {{ article.NumIndicators }}
                  </div>
                  <div class="d-flex">
                    <div class="tag me-1" v-for="tag in article.Tags" :key="tag">
                      <small>{{ tag }}</small>
                    </div> 
                  </div>
                </div>
              </div>
            </div>

            <div @click="load_more()" v-if="more_results" class="search_form text-center load_more">Load more</div>
          </div>
        </div>
      </div>
    </div>

    <slideover :show="show_slideover" :component="slideover_component" :properties="slideover_props" @slideover-close="show_slideover = false">
        <div class="d-flex column" style="padding: 2rem; height: 100%;">
          <div class="d-flex vertically-centered">
              <a :href="selectedArticle.Url">
                <h5 class="m-0 me-3 link mb-2 fw-normal">{{ selectedArticle.Title }}
                  <font-awesome-icon class="me-2" style="color: #536173; font-size: 1rem;" :icon="['fas', 'link']" />
                </h5>
              </a>
          </div>

          <small class="fst-italic mb-3 d-flex">By {{ selectedArticle.OwnerDisplayName }}</small>
          <p class="mb-5" style="max-height: 400px; overflow: scroll;">{{ selectedArticle.Summary}}</p>
              
          <div class="" style="flex-grow: 1; overflow: scroll;">
            <h6>Indicators</h6>
            <p v-if="selectedArticleIndicators.length == 0">None</p>
            <table class="table table-dark" style="">
              <tbody>
                <tr v-for="indicator in selectedArticleIndicators" :key="indicator">
                  <th>{{indicator.Indicator}}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-auto"><a type="button" :href="selectedArticle.Url" class="btn btn-dark">Go to article 🔗</a></div>
        </div>
    </slideover>
  </div>
</template>

<style scoped>
.tag {
  background-color: #13181F;
  padding: .5rem 1rem;
  border-radius: 10px;
  line-height: .875rem;
  color: white;
}

.article {
  padding: .5rem;
  border-radius: 5px;
  transition: background-color 0.4s ease;
}

.article:hover {
  background-color: #21272f;
  cursor: pointer;
}

.link {
  transition: color 0.4s ease;
}

.link:hover {
  color: cornflowerblue;
}

th {
  color: white;
  background-color: transparent;
  font-weight: normal;
}

tr {
  background-color: transparent;
}


.search_input {
  width: 100%;
  display: block;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  padding: .5rem 1rem;
}

.search_form {
  background-color: #151a22;
  padding: .6rem 1.2rem;
  border-radius: 5px;
}

.search_selector {
  cursor: pointer;
  transition: color .4s ease; 
}

.search_selector:hover {
  color: white;
}

.search_input {
  width: 100%;
  display: block;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
}

.active-tag {
  padding: .3rem .8rem;
  border: 1px solid #6d7785;
  border-radius: 30px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.active-tag-cross:hover {
  color:white !important; 
  cursor: pointer;
}

.dropdown-item {
  cursor: pointer;
}

.load_more {
  cursor:pointer;
  transition: color .4s ease; 
}

.load_more:hover {
  color: white;
}
</style>
