<script setup>
import { onMounted, ref, defineProps } from 'vue'
import { useMainStore } from '@/stores/main'

let mainStore = useMainStore()

import DatabaseSlideover from '../../components/Feeds/Slideover/DatabaseSlideover.vue'

const props = defineProps({
  data: Object
});

function click_handler() {
  mainStore.show_slideover(DatabaseSlideover, { 'data': props.data })
}
</script>

<template>
  <div class="box dark padded d-flex column" @click="click_handler" style="color: rgb(157, 165, 176)">

    <div class="title mb-3">
      {{ props.data.title }}
    </div>
    <div class="mb-4">
      <div v-for="feature in props.data.features" class="mb-2 d-flex"  :key="feature">
        <font-awesome-icon style="color: rgb(146, 139, 232); font-size: 1rem; margin-top: .25rem" class="me-3" :icon="['fas', 'circle-check']" />
        <div>
        {{feature}}
        </div>
      </div>
    </div>
    <div class="mt-auto">
      {{ props.data.summary }}
    </div>
    <font-awesome-icon class="me-2 icon" style="z-index: 0" :icon="['fas', props.data.icon]" />
  </div>
</template>

<style scoped>
.box {
  width: 25rem;
  background: transparent;
  border: 2px solid #1e252f;
  height: 100%; 
}

.box > div {
  z-index: 1;
}

.box:hover {
  background-color: #2B3440;
  cursor: pointer;
}

.icon {
  color: #1f252f;
  font-size: 5rem;
  position: absolute;
  right: 5px;
  top: 10px;
}

.title {
  font-size: 1.1rem;
  color: white;
  font-weight: bold;
}

.box:hover .icon {
  color: #323b48
}
</style>
