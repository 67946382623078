export default 
[
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Community",
    "features": [
      "Extensive DNS data from 2012 onwards",
      "Deep enrichment of IPs and Domains",
      "Pivoting and limited API access",
      "10 free queries a day",
      "80 free monthly queries",
      "Up to 200 results"
    ],
    "caption": "This is where you start, and try things out",

    "price": "Free",
    "frequency": "",
  
    "contact_buy": false,
    "account_type": 1,

    "restriction": "Individual use only",
  },
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Professional",
    "features": [
      "Everything in Community, including",
      "Access to the full API",
      "1.000 queries/day",
      "10.000 queries/month",
      "Bulk selector enrichment",
      "Access complete query result",
      "Basic access to streaming API"
    ],
    "caption": "For experts and commercial researchers",

    "price": "$449",
    "frequency": "/month",

    "contact_buy": true,
    "account_type": 2,

    "restriction": "Individual use only",
  },
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Enterprise",
    "features": [
      "Everything in Professional, including",
      "Full access to streams",
      "5+ concurrent users",
      "10.000 daily queries",
      "100.000 monthly queries",
      "Upload raw logs, download in a parsed and enriched format",
      "Access to raw data sets for live on-prem enrichment",
    ],
    "caption": "For security teams, with sophisticated needs",

    "price": "Contact us",
    "frequency": "",

    "contact_buy": true,

    "account_type": 3,

    "restriction": "Enterprise & Commercial use",
  }
  ]
