<script setup>
import {defineProps, useSlots, ref, onMounted} from 'vue'
import CodeInset from '@/components/InlineCode.vue'
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

let slots = useSlots()
const props = defineProps({
  title: String,
  method: String,
  json: {
    type: Boolean,
    default: false
  },
  raw: {
    type: Boolean,
    default: false
  }
});

const data = ref(null)

if (props.json) {
  data.value = JSON.parse(slots.default()[0].children)
}

</script>

<template>
  <div class="block mb-3">
    <div class="title">
      <span v-if="method == 'POST'" class="method" style="color: #13ce66;">{{method}}</span>
      <span v-if="method == 'GET'" class="method" style="color: rgb(92, 178, 242);">{{method}}</span>
      {{ props.title }}
    </div>   
    <div class="content">
      <pre v-if="props.raw"><code>{{ slots.default()[0].children }}</code></pre>
      <vue-json-pretty v-else-if="props.json" :data="data" style="overflow: auto; max-height: 600px; color: white;"/>
      <code-inset v-else>
        <template v-for="(index, name) in $slots" v-slot:[name]>
          <slot :name="name" />
        </template>
      </code-inset>
    </div>   
  </div>
</template>

<style scoped>
pre {
  color: white;
}
.block {
  border-radius: 10px;
  background-color:#5b636f;
}

.method {
  font-weight: normal;
  font-size: .8rem;
  margin-right: .5rem;
}

.block .title {
  background-color:#697280;
  border-radius: 10px 10px 0 0 ;
  padding: .3rem .8rem;
  color: white;
  font-weight: bold;
}

.block .content {
  padding: .8rem;
  overflow: auto;
}
</style>
