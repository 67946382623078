<script setup>
import {computed, onMounted, onUnmounted, ref, watch, nextTick} from 'vue'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';

import { useStreamStore } from '@/stores/stream'
let streamStore = useStreamStore()

// IMPORT WIDGETS
import Country from '@/components/Query/Widget/Country.vue'
import DateI from '@/components/Query/Widget/Date.vue'
import Indicators from '@/components/Query/Widget/Indicators.vue'
import Provider from '@/components/Query/Widget/Provider.vue'
import Anonymization from '@/components/Query/Widget/Anonymization.vue'
import Since from '@/components/Query/Widget/Since.vue'
import Reputation from '@/components/Query/Widget/Reputation.vue'

DataTable.use(DataTablesCore)

const renderComp = ref(true)

let page_size = ref(10);

let options = computed(() => {
  return {
    pagingType: 'simple_numbers',
    paging: true,
    scrollCollapse: true,
    scrollY: true,
    scrollX: true,
    order: { name: 'internal_generated_idx', dir: 'desc'},
    drawCallback: function() {
      document.querySelector(".dt-scroll-body thead").style.display = "none"
    },
    layout: {
        topStart: null,
        topEnd: null,
        bottomStart: "info",
        bottomEnd: "paging"
    },
    pageLength: 50,
  }
})

let result = computed(() => {
  let r = flatten(streamStore.data)

  return {
    data: r,
    columns: generate_columns(r)
  }
})

// we want to flatten some columns but in a standardized way
function flatten(query_result) {
  if (query_result == null || query_result == undefined) return query_result

  for (let i=0; i<query_result.length;i++) {
    flatten_as(query_result[i])
    flatten_provider(query_result[i])
    flatten_article(query_result[i])

    delete_keys(query_result[i])
  }

  return query_result
}

function flatten_as(obj) {
  if ("as" in obj) {
    obj.as_number = obj.as.number.toUpperCase()
    //obj.as_org = obj.as.org
    delete obj.as
  }
}

function flatten_article(obj) {
  delete obj.summary
  delete obj.id
}

function delete_keys(obj) {
}

function flatten_provider(obj) {
  if ("provider" in obj ) {

    if (obj.provider == null) {
      obj.provider_reputation = null
      return 
    }

    obj.provider_reputation = obj.provider.reputation
  }
}

// FIX There could be a possible flaw here, if there are missing fields from the output. Ie. if fields are omitted if they are empty / null
function generate_columns(result) {
  let columns = []
  if (result != null && result.length > 0) {
    for (const [key, value] of Object.entries(result[0])) {
      if (key == "internal_generated_idx") {
        columns.push({ data: key, title: key, name: key, visible: false })
      } else {
        columns.push({ data: key, title: key, name: key })
      }
    }
  }
  return columns
}

const table = ref()
const rtable = ref()
let dt; 

function resize() {
  let table_window = document.querySelector(".dt-layout-table")
  let row = document.querySelector(".querytable tbody tr")
  
  if (table_window == null || row == null) {
    return
  }

  let table_window_size = table_window.offsetHeight
  let row_size = row.offsetHeight
  if (table_window_size == null || row_size == null) {
    page_size.value = 20;
  } else {
    let size = Math.floor((table_window_size-row_size)/row_size)
    if (size > 1) {
      page_size.value = size
    }
  }

  dt.page.len(page_size.value).draw()
}


onMounted(() => {
  dt = table.value.dt;

  //resize()
  //window.onresize = resize
})

</script>

<template>
  <div ref="rtable" class="" style="height: 100%">
      <DataTable
        ref="table"
        :options="options"
        style="height: 100%;"
        :columns="result.columns"
        :data="result.data"
        class="querytable pageResize">

        <template #column-location="props" v-if="result != null">
          <Country :data="props.cellData"/>
        </template>
        
        <template #column-provider="props"  v-if="result != null" >
          <Provider :data="props.cellData"/>
        </template>

        <template #column-indicators="props">
          <Indicators :data="props.cellData" />
        </template>

        <template #column-anonymization="props">
          <Anonymization :data="props.cellData" />
        </template>

        <template #column-update_date="props">
          <Since :data="props.cellData" />
        </template>

        <template #column-publish_date="props">
          <Since :data="props.cellData" />
        </template>

        <template #column-expiration_date="props">
          <DateI :data="props.cellData" />
        </template>

        <template #column-registration_date="props">
          <DateI :data="props.cellData" />
        </template>

        <template #column-provider_reputation="props">
          <Reputation :data="props.cellData" />
        </template>

        <template #column-num_domains="props">
          <div>
            <span v-if="props.cellData >= 1000">1000 <strong>+</strong></span>
            <span v-else>{{props.cellData}}</span>
          </div>
        </template>

      </DataTable>
  </div>
</template>
