<script setup>
import { onBeforeMount } from 'vue'
import { useMainStore } from '@/stores/main'
import Slideover from '@/components/SlideOver/slideover.vue'
import modal from '@/components/modals/modal.vue'

let mainStore = useMainStore()

onBeforeMount( async () => {
  await mainStore.init()
}) 
</script>

<template>
  <div style="height: 100%;" ondragstart="return false;">
    <slideover :show="mainStore.slideover_show" :component="mainStore.slideover_component" :properties="mainStore.slideover_props" @slideover-close="mainStore.close_slideover()"></slideover>
    <modal :show="mainStore.modal_show" :component="mainStore.modal_component" :properties="mainStore.modal_props" @modal-close="mainStore.close_modal()"></modal>
    
    <router-view/>
    <!-- <cookie-consent v-if="!consent"/> -->
  </div>
</template>

<style>
#app {
  /*font-family: Sansation, Montserrat, Arial, sans-serif;*/

  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: Montserrat;
  src: url('~@/assets/fonts/Montserrat-VariableFont_wght.ttf');
}

@font-face {
  font-family: Montserrat;
  src: url('~@/assets/fonts/Montserrat-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: Sansation;
  src: url('~@/assets/fonts/Sansation_Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Sansation;
  src: url('~@/assets/fonts/Sansation_Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Sansation;
  src: url('~@/assets/fonts/Sansation_Light.ttf');
  font-weight: 300;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap');


html, body, #__nuxt {
  min-height: 100% !important;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #191F27;
  font-family: Sansation;
  color: #6D7785;
}

h1,h2,h3,h4,h5,h6 {
  font-family: Roboto;
  font-weight: 900;
  color:white;
}

.wrapper {
    height: 100%;
    display: flex;
    position: relative;
}

.wrapper-content {
    flex: 1;
    min-height: 0;
    /* min-height: 100%; */
    display: flex;
    overflow: auto;
    scroll-behavior: smooth;
    /* overflow-y: scroll; */
}

.mbb-4 {
    margin-bottom: 4rem;
}

.mtt-5 {
    margin-top: 5rem;
}

.mtt-8 {
    margin-top: 8rem;
}

.mbb-5 {
    margin-bottom: 5rem;
}


.content {
    flex: 1; 
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    /* padding-bottom: 1rem; */
    /* overflow: scroll; */
}

.fill {
  flex: 1; 
  display: flex;
}

/* General form styling */

.form-check {
    display: flex;
}


.form-control, .form-select, .form-check-input[type="checkbox"] {
  background-color: #1F2631;
  border:none;
  outline:none;
  color:white;
  font-weight: bold;
  border-radius: 20px;
}

 .form-check-input[type="checkbox"] {
   padding:.7rem;
   border-radius: 6px;
   margin-right: 0.7rem;
 }

.form-control:active, .form-control:focus, .form-check-input[type="checkbox"] {
  background-color: #1F2631;
  outline: none;
  box-shadow: none;
  color:white;
}


 .form-control.dark, .form-control.dark:active, .form-check-input.dark, .form-control.dark:focus {
  background-color: #171C24;
 }

.form-floating > label {
    font-weight: bold;
    color: #6D7785;
    padding: 1.3rem 2rem;
}

.form-select > label {
    font-weight: bold;
    color: #6D7785;
    padding: 1.3rem 2rem;
}

.form-select:focus-visible {
  color: white;
  /* text-shadow: 0 0 0 #212529; */
  text-shadow: none;
}

.form-floating > .form-control {
  padding: 2rem 2rem;
  padding-left: 1.8rem;
}

.wrap {
  flex-wrap: wrap;
}

.form-floating > .form-select {
  padding-top: 1.2rem;
  padding-left: 1.6rem;
  padding-bottom: 0rem;
  height: 64px;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
}

.form-check-label {
  line-height: 2rem;
  color: white;
}

.ws-gradient {
  background: linear-gradient(263.46deg, #4A97F2 0%, #C777FF 50%, #EC4777 100%);
}

.ws-gradient-text {
  background: linear-gradient(263.46deg, #4A97F2 0%, #B880FF 40.89%, #FE79A3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

a {
  text-decoration: none;
}

.alert {
  border-radius: 10px;
}

.box {
    background-color: #1E252F;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}


.box.seethrough {
    background-color: #1E252FCF;
  }

.box.padded {
    padding: 1.5rem 2rem;
}

.box.dark {
  background-color: #171C24;

}

.box.small-padded {
  padding: .4rem 1.1rem;
}

.box.med-padded {
    padding: .7rem 1.5rem;
}

.box.vert-center {
  align-items: center;
}


.box.hori-center {
  justify-content: center;
}

.box.success {
  background-color: #334d37;
}

.white {
  color: white;
}

.box.squared {
  border-radius: 10px;
}

.box.bg-d {
  background-color: #191F27;
}

.box.bg-l {
  background-color: #222A35;
}

.box .header {
    padding: 1.5rem 2rem;
}

.box .element-header {
    display: flex;
    
  }

.box .element-header .main {
    color: white; 
    background-color: #2C3542;
    padding: .8rem 3rem .8rem 1.5rem;
    border-radius: 0 0 20px 0; 
    font-weight: bold;
    font-size: 1.1rem;
    z-index: 1;
  }

.element-header .sub {
    background-color: #232B37;
    padding: .8rem 3rem .8rem 3rem;
    border-radius: 0 0 20px 0; 
    left: -10px;
    position: relative;
  }

.box.clickable {
  cursor: pointer;
}

.box.clickable:hover {
    background-color: #262e3a;
}

.box.success:hover {
  background-color: #3e5c42;
}

.box.faded {
  opacity: .4;
}

.box.faded.clickable {
  cursor:pointer;
  transition: opacity ease .35s;
}

.box.faded.clickable:hover {
  opacity: .7;
}


.modal-header {
  padding: 1rem 1.5rem; 
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.modal-content {
  background-color: #191F27;
  border-radius: 20px;
}

.modal-body {
    padding: 3rem;
}


.bg-360 {
  background-color: #5094ED;
}

.bg-public {
  background-color: #E780F5;
}

.bg-scan {
  background-color: #7357D2;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: .5rem 2rem .5rem 1rem;
  border-radius: 10px;
}

.dropdown-item:hover {
    background-color: #eff3f7;
    /* background-color: transparent; */
}

.subheader {
    font-weight: 500;
    color: #536173;
    font-family: Sansation;
}

.box.transparent {
    background-color: transparent;
  }

.danger-bg {
  background-color: #9E4A4A; 
  stroke: #9E4A4A; 
  color: white;
}

.danger-bg-darker {
  background-color: #954242;
  stroke: #954242;
}

.warning-bg {
  background-color: #E7C85D;
  stroke: #E7C85D;
  color: white; 
}

.notice-bg {
  background-color: #46b8dd;
  color: white;
}

.good-bg {
  background-color: #5aa150;
  color:white;
}

.btn-continue {
    padding: .6rem 2rem;  
    background-color:#7357D2;
    font-weight: bold;
    outline:none;
    box-shadow: none;
    border:none;
    color: white;
    border-radius: 30px;
}

.btn-continue:hover {
    background-color: #7F62E3;
}

.btn-pink {
    padding: .6rem 2rem; 
    background-color:#E780F5;
    font-weight: bold;
    outline:none;
    box-shadow: none;
    border:none;
    color: white;
    border-radius: 30px;
}

.btn-pink:hover {
    background-color: #DA92E3;
    color:white;
}

.btn-blue {
    padding: .6rem 2rem; 
    background-color:#5094ED;
    font-weight: bold;
    outline:none;
    box-shadow: none;
    border:none;
    color: white;
    border-radius: 30px;
}

.btn-blue:hover {
    background-color: #63A1F2;
    color:white;
}

.alert-box {
  line-height: 1rem;
  background-color: #E75D5D;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: .2rem 0.6rem;
  display: flex;
  align-items: center;
  font-size: .9rem;
}

.centered {
  justify-content: center;
}

.column {
  flex-direction: column;
}
.box.drow {
  flex-direction: row;
}

.drow.centered {
  align-items: center
}


.column.centered {
  align-items: center;
}

.vertically-centered {
  align-items: center;
}

.column.vertically-centered {
  justify-content: center;
}


.warning-box {
  line-height: 1rem;
  background-color: #E7C85D;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: .2rem 0.6rem;
  display: flex;
  align-items: center;
  font-size: .9rem;
}

.info-box {
  line-height: 1rem;
  background-color: #5D62E7;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: .2rem 0.6rem;
  display: flex;
  align-items: center;
  font-size: .9rem;
}

.grayed {
    color: #C8D1DE;
}

/* DataTable styling */
.datatable tr {
  border-color: #2e3742;
  cursor: pointer;
}

.datatable td {
  color: white !important;
}

::-webkit-scrollbar {
  width: 20px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #1f252f;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

::-webkit-scrollbar-button { 
   display:none;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
