export default 
[
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Phising Sites",
    "features": [
      "Gather active and novel phishing campaigns",
      "Uncover more than 1.000 suspicious websites daily",
      "Protect yourself by feeding the stream into your SIEM",
      "Build datasets to train AI and ML models",
    ],
    "summary": "Active phishing sites and suspicious domains based on deep learning",
    "icon": "fish",

    "items": "~1.000 per day",
    "update_frequency": "live",

    "schema": { "start_url": "http://example.com", "end_url": "https://example.com/index.html", "redirects": 1, "ip": "12.34.56.78", "location": { "country_name": "United States", "country_iso": "US"}, "probability": 0.94, "verdict": "phishing", "source_url": "https://api.webscout.io/files/scan/023912323412341234123/source.html", "data_file_url": "https://api.webscout.io/files/scan/023912323412341234123/data.json"},
    "description": `
The Streaming API for newly identified phishing sites delivers enriched, contextual data from more than 1,000 suspicious websites identified daily as part of active phishing campaigns. This API allows
organizations to gather intelligence on phishing operations and integrate these insights directly into their Security Information and Event Management (SIEM) systems for enhanced protective measures. It is a
valuable resource for cybersecurity teams, data scientists, and AI researchers focused on developing advanced machine learning models to detect and counteract phishing threats. Potential use cases
include real-time threat detection, training AI models for automated threat recognition, and strengthening cybersecurity defenses by staying ahead of emerging phishing techniques.
    `,

    "sample_url": "",
  },
  
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Open Ports",
    "features": [
      "Service discovery on the entire IPv4 space",
      "Monitor potentially exposed services",
      "Identify adversary infrastructure",
      "Cluster systems and services on internet-wide scale"
    ],
    "summary": "Data from our pro-active port scanning of the entire IPv4 address space.",
    "icon": "shield-halved",

    "items": "~20m per day",
    "update_frequency": "live",

    "schema": { "ip": "12.34.56.78", "ports": [80, 443, 25, 5432], "ttl": 64},
    "description": `
The streaming API for open ports offers a live feed of all the exposed systems and services we
discover in our internet-wide scanning. By probing the entire IPv4 range for open ports, the API
facilitates detailed oversight of global network infrastructure. This capability is crucial for cyber threat
intelligence teams, defense agencies, and research institutions looking to understand adversarial
infrastructure and safeguard their networks. Potential use cases include real-time threat detection,
vulnerability research, and enhancing the effectiveness of defensive strategies against cyberattacks.
    `,

    "sample_url": "",
  },
  {
    "id": "0000-0000-000-00000-00000",
    "title": "URL scans",
    "features": [
      "Over 50.000 unique reports daily",
      "Hook into streams of deep URL profiling",
      "Monitor suspicious web fingerprints",
      "Protect your brand from fraud"
    ],
    "summary": "Data from our pro-active scanning and profiling of URLs on internet-wide scale.",
    "icon": "globe",

    "items": "~50.000 per day",
    "update_frequency": "live",

    "schema": { "start_url": "http://example.com", "end_url": "https://example.com/index.html", "redirects": 1, "ip": "12.34.56.78", "location": { "country_name": "United States", "country_iso": "US"}, "source_url": "https://api.webscout.io/files/scan/023912323412341234123/source.html", "data_file_url": "https://api.webscout.io/files/scan/023912323412341234123/data.json"},
    "description": `
The URL scan streaming API provides continuous data from our proactive scans of URLs across
the internet, delivering over 50,000 unique reports each day. Monitor data from deeply profiled and
fingerprinted websites to detect threats or track adversarial infrastructure. The service is particularly
valuable for cybersecurity firms, digital marketers, and brand protection agencies that need to safeguard
their online presence against fraudulent activities. Potential use cases include real-time monitoring of
phishing attempts, tracking of illegal content distribution, and prevention of brand impersonation to
maintain a strong reputation and integrity
    `,

    "sample_url": "",
  },
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Newly registered domains",
    "features": [
      "Monitor over 10.000 newly registered domains daily",
      "Protect your brand against fraud",
      "Detect typosquatted domains before weaponization",
      "Analyse web trends and topics"
    ],
    "summary": "Constant feed of newly registered domains with DNS information included.",
    "icon": "at",

    "items": "~10.000 per day",
    "update_frequency": "live",

    "schema": { "domain": "example.com", "ip": "12.34.56.78", "location": { "country_name": "United States", "country_iso": "US"}, "registration_date": "2024-10-15T13:48:30.230398Z" },
    "description": `
The Streaming API for newly registered domains provides a real-time feed of data on over
10,000 domains registered daily. This powerful tool helps organizations monitor and analyze emerging
web trends and topics, detect potentially harmful typosquatted domains before they are weaponized,
and protect their brand integrity. It is essential for cybersecurity teams, domain registrars, and brand
protection agencies aiming to preemptively address threats and exploit market insights. Potential use
cases include early detection of phishing campaigns, competitive intelligence gathering, and strategic
marketing based on trending topics and domain registrations.
    `,

    "sample_url": "",
  },
  /*
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Open Ports",
    "features": [
      "Datacenter VPNs & Proxies",
      "40 largest VPN providers",
      "More than 1 million IP addresses",
      "Updated daily"
    ],
    "description": "lorem",
    "icon": "shield-halved"
  },

  {
    "id": "0000-0000-000-00000-00000",
    "title": "Netflow",
    "features": [
      "Datacenter VPNs & Proxies",
      "40 largest VPN providers",
      "More than 1 million IP addresses",
      "Updated daily"
    ],
    "description": "lorem",
    "icon": "water"
  },
  */
]
