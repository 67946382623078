<script setup>
  import { onMounted, ref, onUnmounted,  defineProps } from 'vue'
  
  let ul = ref()

  const props = defineProps({
    words: {
      type: Array,
      default: []
    },
    height: {
      type: String,
      default: "1rem"
    },
    offsetTop: {
      type: String,
      default: "0"
    },
    listClass: {
      type: Array,
      default: []
    }
  }); 

  props.words.push(props.words[0])

  var interval;
  var current_word = 0; 

  onMounted(() => {
    interval = setInterval(() => {
       // next word index
       // index of next word
       current_word = ++current_word % props.words.length;

       if (current_word == 0) {
        ul.value.style.transition = "none" 
        ul.value.style.transform = "translateY(0rem)"
       } else {
        ul.value.style.transition = "all 0.5s" 
        ul.value.style.transform = "translateY(-"+ current_word*props.height + "rem)"
       }

    }, 3000)

  })

  onUnmounted( () => {
    clearInterval(interval)
  })
</script>

<template>
  <div class="m" :style="{'height': props.height + 'rem', 'top': props.offsetTop + 'rem' }" >
    <ul ref="ul"> 
      <li v-for="word in props.words" >
        <div :class="props.listClass">
        {{word}}
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>

.m {
  display: inline-block;
  overflow: hidden; 
  position: relative;
}

ul {
  list-style: none;
  padding: 0;
  margin:0;
  transition: all 0.5s; 
}

li {
  text-align: left;
}

li > div {
  display: inline;
}
  
</style>
