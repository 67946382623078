<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
import { useStreamStore } from '@/stores/stream'
let streamStore = useStreamStore()

import Streams from '../../api/Streams.js'
//const user = useUserStore()
let streams = ref([])

//let LoadingStream = ref(false)
let FullStream = ref(null)
let FullStreamItems = ref([])
let FullStreamItemsLoading = ref(false)

onMounted(async () => {
  streams.value = (await Streams.list()).data
})

function view(stream_id) {
    streamStore.listen(stream_id)
    router.push({
      name: "UserStream",
    })
}

</script>

<template>
  <div style="min-height: 100%;" class="p-3">
    
    <div class="mb-5">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <font-awesome-icon style="color: #e7f25c; font-size: 1.2rem;" class="me-4" :icon="['fas', 'square-rss']" />
        <h5 class="mb-0">Your streams</h5>
      </div>
      
      <div class="d-flex">
        <div v-for="stream in streams" class="me-3" :key="stream">
          <div class="box med-padded">
            <h5 class="white">
              {{ stream.name }} 
            </h5>

            <div class="mb-2">
              {{ stream.id }} 
            </div>

            <button class="btn btn-primary" @click="view(stream.id)">view</button>
          </div>
        </div> 
      </div>
    </div>

  </div>
</template>

<style scoped>
.box.active {
  background-color: #262e3a;
}

.tab div {
  overflow: hidden;
}
</style>
