export default
[
  {
    "id": "0000-0000-000-00000-00000",
    "title": "VPN infrastructure",
    "features": [
      "Commercial VPN and Proxy IPs",
      "Detect anonymization and threats",
      "Combat cybercriminals, bots, and scrapers",
      "Supercharge digital investigation processes"
    ],
    "summary": "Attributed and unattributed VPN and Datacenter proxies",
    "icon": "user-secret",

    "schema": {"ip_address": "104.234.212.34", "hostname": "node-us-237.protonvpn.net", "timestamp": "2024-04-01T10:27:53.836372+02:00", "tag": "VPN", "provider_uuid": "86b708e6-4a54-45ea-9a06-d315f4286f4d", "provider_name": "Proton VPN", "is_exit": null, "description": "", "summary": ""},
    "description": `
The raw data dataset of IP addresses attributed to commercial VPN providers is a
comprehensive resource comprising IPs from the 30 largest VPN services, including those used for
residential VPNs and crypto networks like Bright Data (formerly Luminati) and Mysterium. This dataset is
continually updated on a weekly basis to include new providers and reflect changes such as the addition
or removal of nodes. This dataset is particularly valuable for cybersecurity professionals, streaming
services, and large corporate networks, as detecting traffic tunneled through anonymization networks is
vital to combating fraud and cybercrime. It provides unique insights for maintaining the security and
integrity of digital operations against sophisticated threats
    `,

    "sample_url": "https://files.webscout.io/samples/vpn_infrastructure.json"
  },

  {
    "id": "0000-0000-000-00000-00000",
    "title": "Cloud infrastructure",
    "features": [
      "All popular cloud, datacenter, and CDN provider IPs",
      "Enriched with service- and region-specific information where possible",
      "Ideal for air-gapped and Rapidly Contextualize IP addresses at scale",
      "Map organizations’ digital assets across providers"
    ],
    "summary": "Complete mapping of infrastructure- and services of all major cloud providers",
    "icon": "globe",

    "schema": {"ip_address": "4.145.74.52/30", "offers_hosting": true, "offers_colocation": false, "offers_cloud_storage": true, "offers_cdn": true, "offers_cloud_computing": true, "provider_name": "Microsoft Azure", "service": "Azure", "service_info": "ActionGroup", "service_region": "", "provider_type": "cloud", "provider_reputation": "very good", "provider_reputation_score": 1, "timestamp": "2024-04-18T13:51:37.505000+02:00", "provider_uuid": "e3d79c6d-b122-4748-bf9a-ff207c13ac21", "description": "", "summary": ""},
    "description": `
This dataset comprises IP addresses and ranges from all the largest cloud providers and
datacenters, including service- and region-specific details (e.g., Google Drive, Amazon EC2, Microsoft
Azure). Use the data to make sense of large volumes of network traffic in cases where API access is not
feasible and track digital assets across various cloud providers and datacenters [link to wiki example
“Case 2: Detect anonymization and threats in logs”]. Each provider is assigned a risk score based on the
relative frequency of indicators of compromise (IoC), allowing for the filtering of data based on
datacenter reputation—datacenters known for offering cheap virtual computing, like OVH and Contabo,
generally have a higher risk score compared to those of large commercial providers like Amazon AWS
and Azure. This dataset is continually updated to reflect the addition or removal of infrastructure by the
providers and is offered as a one-time download "as is".
    `,

    "sample_url": "https://files.webscout.io/samples/cloud_infrastructure.json"
  },


  {
    "id": "0000-0000-000-00000-00000",
    "title": "Forward DNS",
    "features": [
      "Coverage of all TLDs",
      "Bulk-map IP addresses to domains",
      "Enumerate subdomains at scale",
      "Uncover hidden patterns in web systems and services"
    ],
    "summary": "Over 2 billion type A DNS records covering all major TLDs",
    "icon": "at",

    "schema": { "domain": "example.com", "ip": "12.34.56.78", "timestamp": "2024-10-15T13:48:30.230398Z" },
    "description": `
This collection, sourced from both active queries and
passive data, provides detailed information that's essential for anyone looking to map IP addresses to
domain names and uncover subdomains. It's especially useful for cyber security professionals, SEO
experts, and those managing large online assets. By offering insights into the domain structure across
the internet, this dataset helps these professionals keep track of digital footprints, understand web
hosting patterns, and identify potential security risks. Whether you're monitoring network security or
improving your website's search engine rankings, this dataset provides the tools needed for
comprehensive web analysis
    `,

    "sample_url": "https://files.webscout.io/samples/forward_dns.json"
  },

  {
    "id": "0000-0000-000-00000-00000",
    "title": "WHOIS",
    "features": [
      "Investigate domain registration details",
      "Cluster domains based on ownership",
      "Gather e-mail addresses, phone numbers, and more",
      "Hunt malicious infrastructure"
    ],
    "summary": "Over 65 million parsed WHOIS records across every TLD.",
    "icon": "book",

    "schema": {"domain":"exceeding.co.uk","createdDate":"2011-04-17T00:00:00Z","expirationDate":"2024-04-17T00:00:00Z","updateDate":"2023-04-16T00:00:00Z","dnssec":false,"Status":["registered"],"name_servers":["abby.ns.cloudflare.com","cody.ns.cloudflare.com"],"registrar_name":"Ionos SE [Tag = 1AND1]","registrar_org":"","registrar_country":"","registrar_email":"","registrar_phone":"","registrant_name":"","registrant_org":"","registrant_email":"","registrant_phone":"","registrant_country":"","registrant_city":"","registrant_street":"","registrant_postal":""},
    "description": `
The WHOIS dataset provides an extensive collection of over 65 million parsed WHOIS records
from all top-level domains (TLDs) overseen by ICANN. It is a valuable resource for digital forensics and
cybersecurity analysis, offering detailed information on domain registrations on a global scale. By
providing data such as contact email addresses, phone numbers, and other contact details, this dataset
enhances the ability to conduct investigations into websites. Organizations can use this information to
group domains by ownership, aiding in the detection of patterns related to malicious activities. The
dataset supports various applications, including tracking adversary infrastructure, studying domain
registration trends, and bolstering cybersecurity efforts by identifying potentially harmful entities.
    `,

    "sample_url": "https://files.webscout.io/samples/whois.json"
  },

  /*
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Cloud infrastructure",
    "features": [
      "Datacenter VPNs & Proxies",
      "Covers the 40 largest VPN providers",
      "+1 million IP addresses",
      "Updated daily"
    ],
    "description": "Attributed and unattributed VPN and Datacenter proxies",
    "icon": "globe"
  },
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Forward DNS",
    "features": [
      "Datacenter VPNs & Proxies",
      "Covers the 40 largest VPN providers",
      "+1 million IP addresses",
      "Updated daily"
    ],
    "description": "Attributed and unattributed VPN and Datacenter proxies",
    "icon": "at"
  },
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Reverse DNS",
    "features": [
      "Datacenter VPNs & Proxies",
      "Covers the 40 largest VPN providers",
      "+1 million IP addresses",
      "Updated daily"
    ],
    "description": "Attributed and unattributed VPN and Datacenter proxies",
    "icon": "at"
  },
  {
    "id": "0000-0000-000-00000-00000",
    "title": "WHOIS",
    "features": [
      "Datacenter VPNs & Proxies",
      "Covers the 40 largest VPN providers",
      "+1 million IP addresses",
      "Updated daily"
    ],
    "description": "Attributed and unattributed VPN and Datacenter proxies",
    "icon": "book"
  },
  */
]

