<script setup>
import { onMounted, useSlots, onUnmounted } from 'vue'

var interval;

//let slots = useSlots();

onMounted( () => {
  /*
  let i = 0; 
  document.querySelectorAll(".cascade-slider_item").forEach( (e) => {
    e.setAttribute("data-slide-number", i)
    i += 1;
  })
  */

  //console.log(slots)

  changeIndex(0)
  
  let t = 1; 
  interval = setInterval(() => {
    changeIndex(t % 6) 
    t += 1
  }, 5000);
})

onUnmounted( () => {
  clearInterval(interval)
})



function changeIndex(nowIndex) {
  let slides = document.querySelectorAll(".cascade-slider_item")

  let now = document.querySelector('.now')
  let next = document.querySelector('.next')
  let prev = document.querySelector('.prev')
  // clern all class
  if (now != undefined) now.classList.remove('now');
  if (next != undefined) next.classList.remove('next');
  if (prev != undefined) prev.classList.remove('prev');


  if(nowIndex == slides.length-1){
    slides[0].classList.add('next');
  }
  if(nowIndex == 0) {
    slides[slides.length - 1].classList.add('prev');
  }

  slides.forEach(function(elem, index) {
    if(index == nowIndex) {
      slides[index].classList.add('now');
    }
    if(index == nowIndex + 1 ) {
      slides[index].classList.add('next');
    }
    if(index == nowIndex - 1 ) {
      slides[index].classList.add('prev');
    }
  });
}
</script>

<template>
  <div style="postion: relative;">
    
<div class="cascade-slider_container" id="cascade-slider">

  <div class="cascade-slider_slides">

    <div class="cascade-slider_item">
      <slot name="1"></slot>
    </div>
    <div class="cascade-slider_item">
      <slot name="2"></slot>
    </div>
    <div class="cascade-slider_item">
      <slot  name="3"></slot>
    </div>
    <div class="cascade-slider_item">
      <slot  name="1"></slot>
    </div>
    <div class="cascade-slider_item">
      <slot  name="2"></slot>
    </div>
    <div class="cascade-slider_item">
      <slot name="3"></slot>
    </div>
    <!--
    <slot class="cascade-slider_item" name="1"></slot>
    <slot class="cascade-slider_item" name="2"></slot>
    <slot class="cascade-slider_item" name="3"></slot>

    <slot class="cascade-slider_item" name="1"></slot>
    <slot class="cascade-slider_item" name="2"></slot>
    <slot class="cascade-slider_item" name="3"></slot>
    <div class="cascade-slider_item">
       <div class='card align'>
        <div class='photo'>
          <img src="https://agenciafagulha.com.br/wp-content/uploads/2019/12/Asset-1localização-1.png">
          </div>
          <div class='info'>
            <h2>Cars</h2>
          </div>
        </div>
    </div>
    <div class="cascade-slider_item">
       <div class='card align'>
        <div class='photo'>
          <img src="https://agenciafagulha.com.br/wp-content/uploads/2019/12/Asset-1localização-1.png">
        </div>
        <div class='info'>
          <h2>Bolders</h2>
        </div>
      </div>
    </div>
    <div class="cascade-slider_item">
       <div class='card align'>
        <div class='photo'>
          <img src="https://agenciafagulha.com.br/wp-content/uploads/2019/12/Asset-1localização-1.png">
        </div>
        <div class='info'>
          <h2>And other things</h2>
        </div>
      </div>
    </div>
    -->

  </div>

</div>

  </div>
</template>

<style scoped>
  
/*Slider Classes*/
.cascade-slider_container {
  position: relative;
  width: 100%;
  height: 700px;
}
.cascade-slider_item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0.3);
  transition: all 1s ease;
  opacity: 0;
  z-index: -1;
  text-align: center;
  width:900px;
}
.cascade-slider_item.next {
  left: 50%;
  transform: translateY(-50%) translateX(-135%) scale(0.8);
  filter: brightness(80%);
  opacity: 1;
  z-index: 1;
}
.cascade-slider_item.prev {
  left: 50%;
  transform: translateY(-50%) translateX(35%) scale(0.8);
  filter: brightness(80%);
  opacity: 1;
  z-index: 1;
}
.cascade-slider_item.now {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(1);
  opacity: 1;
  z-index: 5;
}
.cascade-slider_arrow {
  display: inline-block;
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 5;
}
.cascade-slider_arrow-left {
  left: 20px;
}
.cascade-slider_arrow-right {
  right: 20px;
}
.cascade-slider_arrow-left img {
  height: 40px;
  left: 40px;
}
.cascade-slider_arrow-right img {
  height: 40px;
  left: 40px;
}
.cascade-slider_nav {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  z-index: 5;
}
.cascade-slider_dot {
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 1px solid #4196EF;
  margin: 3px;
  transition: all 0.5s;
  background: none;
  list-style: none;
  cursor: pointer;
  border-radius: 50px;
}
.cascade-slider_dot:hover {
  background: #2c8fab;
}
.cascade-slider_dot.cur {
  background: #2c8fab;
  width: 17px;
  height: 17px;
  
}


@media (min-width: 460px) and (max-width: 680px) {
  .cascade-slider_item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0.3);
    transition: all 1s ease;
    opacity: 0;
    z-index: -1;
    text-align: center;
  }
  .cascade-slider_item.next {
    left: 50%;
    transform: translateY(-50%) translateX(-90%) scale(0.4);
    filter: brightness(70%);
    opacity: 1;
    z-index: 1;
  }
  .cascade-slider_item.prev {
    left: 50%;
    transform: translateY(-50%) translateX(-10%) scale(0.4);
    filter: brightness(70%);
    opacity: 1;
    z-index: 1;
  }
  .cascade-slider_item.now {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0.6);
    opacity: 1;
    z-index: 5;
  }
  .cascade-slider_arrow-left img {
    height: 30px;
    left: 30px;
  }
  .cascade-slider_arrow-right img {
    height: 30px;
    left: 30px;
  }
  .cascade-slider_nav {
    display:none;
  }
}
@media (max-width: 460px) {
  .cascade-slider_item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0.3);
    transition: all 1s ease;
    opacity: 0;
    z-index: -1;
    text-align: center;
  }
  .cascade-slider_item.next {
    left: 50%;
    transform: translateY(-50%) translateX(-80%) scale(0.3);
    filter: brightness(70%);
    opacity: 1;
    z-index: 1;
  }
  .cascade-slider_item.prev {
    left: 50%;
    transform: translateY(-50%) translateX(-20%) scale(0.3);
    filter: brightness(70%);
    opacity: 1;
    z-index: 1;
  }
  .cascade-slider_item.now {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0.5);
    opacity: 1;
    z-index: 5;
  }
  .cascade-slider_arrow-left img {
    height: 30px;
    left: 30px;
  }
  .cascade-slider_arrow-right img {
    height: 30px;
    left: 30px;
  }
  .cascade-slider_nav {
    display:none;
  }
}
</style>
