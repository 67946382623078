import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class Ip {
  async bulk(items) {
    let { data } = await axios.post(API_URL + '/query/ip', { ip: items });
    return data;
  }
}

export default new Ip();
