<script setup>
import {onMounted, ref, defineProps, computed, nextTick, watch} from 'vue'
import { useQueryStore } from '@/stores/query'
import { useMainStore } from '@/stores/main'
import Domain from '@/api/Domain'
import Ip from '@/api/Ip'
import Streams from '@/api/Streams'
import QueryGuide from '@/views/Authenticated/Query/Guide.vue'
import CellExpandedSlideover from '@/components/Query/CellDataSlideover.vue'

let mainStore = useMainStore()
let queryStore = useQueryStore()

import QueryTable from '@/components/Query/Table.vue'
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

async function copy_result_to_clipboard() {
  let str = JSON.stringify(queryStore.result);
  await navigator.clipboard.writeText(str)
}

const renderTable = ref(true)

watch( () => queryStore.query_string, async ()=> {

  renderTable.value = false;

  await nextTick()

  renderTable.value = true;
})

let actions = {
  'num_emails': async (row) => {return [(await Domain.emails(row.domain)).data, "Emails for " + row.domain]},
  'num_ips': async (row) => {return [(await Domain.resolves(row.domain)).data, "Resolves for " + row.domain]},
  'num_subdomains': async (row) => {return [(await Domain.subdomains(row.domain)).data, "Subdomains for " + row.domain]},
  'ips': async (row) => {return [(await Ip.bulk(row.ips)).data, "Ips for " + row.domain]},
  'domains_on_ip': async (row) => {return [(await Domain.reverse_dns(row.ip)).data, "Domains for " + row.ip]},
  'osint_mentions': async (row) => {
    let value = row.domain
    if (row.domain == undefined) value = row.ip
    return [(await Streams.contains(value)).data, "OSINT Mentions for " + value]
  },
}

async function handle_cell_click(data) {
  let cellname = data.cell_name;
  
  if (actions.hasOwnProperty(cellname)) {
    let c = await actions[cellname](data.obj)
    let title = c[1]
    let d = c[0]
  
    mainStore.show_slideover(CellExpandedSlideover, { data: { 'title': title, 'data': d }})

  } 

}
</script>

<template>
  <div v-if="!queryStore.failed" style="height: 100%; display: flex; ">

    <div v-if="!queryStore.empty && renderTable && !queryStore.running" style=" display: flex; min-height: 0; flex: 1; flex-direction: column;">
        <vue-json-pretty v-if="queryStore.display_json" :data="queryStore.result" style="overflow: auto; padding: 1rem; flex:1;"/>
        <QueryTable v-else style="min-height: 0; flex: 1;" @rowclick="handle_cell_click" />
        <div class="jsonheader">
          <div class="clickable" @click="copy_result_to_clipboard">
            <font-awesome-icon class="me-2" style="color: white; font-size: 1rem;" :icon="['fas', 'copy']" />
            copy to clipboard
          </div>
          <div class="clickable" @click="queryStore.display_json = !queryStore.display_json">
            <font-awesome-icon v-if="queryStore.display_json" class="me-2" style="color: white; font-size: 1rem;" :icon="['fas', 'table-list']" />
            <font-awesome-icon v-else class="me-2" style="color: white; font-size: 1rem;" :icon="['fas', 'code']" />
            <span v-if="queryStore.display_json">display as table</span>
            <span v-else>display as json</span>
          </div>
        </div>
    </div>
    <query-guide v-else/>
  </div>
  <div v-else>
    <div class="d-flex column centered pt-5" style="height: 100%;">
      <font-awesome-icon style="font-size: 7rem; color: rgb(202, 80, 80);" class="mb-4" :icon="['fas', 'circle-exclamation']"/>
      <div>
        <h4 class="text-center">Query failed</h4>
        <p class="text-center">
            {{ queryStore.error }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

.jsonheader {
  display: flex;
  background-color: #151a22;
  border-top: 2px solid #191f27;
  color: white;
  height: 43px;
}

.jsonheader div {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-left: 1px solid #191f27;
}

.clickable:hover {
  background-color: #2B3440;
  cursor: pointer;
}

.clickable {
  font-weight: normal;
}
</style>

